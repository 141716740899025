import React from 'react'
import Tags from './Tags'
import { rhythm } from '../utils/typography'
import { Link } from 'gatsby'

const PostInfo = ({ post, musing }) => {
  const tags = post.fields.tagSlugs && <Tags node={post} />

  return (
    <small
      style={{
        display: 'block',
        marginTop: musing ? 0 : rhythm(1 / 2),
        marginBottom: musing ? rhythm(1 / 8) : rhythm(1 / 2),
        marginRight: 36,
      }}
    >
      <time>
        {musing ? (
          <Link to={post.fields.slug}>{post.frontmatter.date}</Link>
        ) : (
          post.frontmatter.date
        )}
      </time>
      {post.frontmatter.location && (
        <span> · 📍 {post.frontmatter.location}</span>
      )}
      {tags && ` · `}
      {tags}
    </small>
  )
}

export default PostInfo
