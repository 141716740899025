import { Link } from 'gatsby'
import React from 'react'
import { rhythm } from '../utils/typography'
import PostInfo from './PostInfo'
import Typed from './Typed'

const BlogPostListing = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug
  return (
    <>
      <h2 style={{ marginBottom: rhythm(-3 / 8), marginTop: 0 }}>
        <Typed ele={Link} to={node.fields.slug}>{title}</Typed>
      </h2>
      <PostInfo post={node} />
      <Typed ele="div" style={{ marginBottom: rhythm(1) }}>{node.excerpt}</Typed>
    </>
  )
}

export default BlogPostListing
