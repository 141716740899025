import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { rhythm } from '../utils/typography'
import PostInfo from './PostInfo'

const MusingPostListing = ({ node }) => {
  return (
    <>
      <PostInfo post={node} musing />
      <MDXRenderer>{node.body}</MDXRenderer>
      <span style={{ marginBottom: rhythm(1 / 4) }} />
    </>
  )
}

export default MusingPostListing
