import React from 'react'
import { Link } from 'gatsby'

const Tags = ({ node }) => {
  return node.fields.tagSlugs.map((tag, i) => {
    const divider = i < node.fields.tagSlugs.length - 1 && <span>{', '}</span>
    return (
      <React.Fragment key={tag}>
        <Link to={tag} className="p-category">
          {node.frontmatter.tags[i]}
        </Link>
        {divider}
      </React.Fragment>
    )
  })
}

export default Tags
