import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import MusingPostListing from '../components/MusingPostListing'
import BlogPostListing from '../components/BlogPostListing'
import { rhythm } from '../utils/typography'
import SEO from '../components/seo'

const getListing = node =>
  node.frontmatter.title ? (
    <BlogPostListing node={node} key={node.id} />
  ) : (
    <MusingPostListing
      node={node}
      key={node.id}
      style={{
        marginTop: rhythm(1),
      }}
    />
  )

const BlogPostIndex = ({ data }) => (
  <Layout>
      <SEO title="Blog" />
    <h1>Blog</h1>
    {data.allMdx.nodes.map(getListing)}
  </Layout>
)

export default BlogPostIndex

export const pageQuery = graphql`
  query {
    allMdx(
      filter: {
        fields: { collection: { eq: "blog" } }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        body
        excerpt
        fields {
          tagSlugs
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          dateTime: date(formatString: "MMMM Do, YYYY, h:mm A")
          dataDate: date
          tags
        }
      }
    }
  }
`
