import React from 'react'
import typeset from '../utils/typeset'

const Typed = ({ ele, children, ...otherProps }) => {
  const Component = ele || 'span'

  return (
    <Component
      {...otherProps}
      dangerouslySetInnerHTML={{ __html: typeset(children) }}
    />
  )
}

export default Typed
